/* jshint browser: true */

/* jshint unused: false */

/* global arangoHelper, Backbone, window, $, _, frontendConfig */
(function () {
  'use strict';

  window.InfoView = Backbone.View.extend({
    el: '#content',
    initialize: function initialize(options) {
      this.collectionName = options.collectionName;
      this.model = this.collection;
    },
    events: {},
    render: function render() {
      var self = this;
      this.breadcrumb();
      window.arangoHelper.buildCollectionSubNav(this.collectionName, 'Info');
      window.arangoHelper.disableSubNavBar();

      if (frontendConfig.isCluster) {
        var clusterData = {};

        var callbackShardCount = function callbackShardCount(error, data) {
          if (error) {
            arangoHelper.arangoError('Figures', 'Could not get figures.'); // in error case: try to render the other information

            self.renderInfoView();
          } else {
            clusterData.shardCounts = data.count;
            self.renderInfoView(clusterData);
          }
        };

        this.model.getShardCounts(callbackShardCount);
      } else {
        this.renderInfoView();
      } // check permissions and adjust views


      arangoHelper.checkCollectionPermissions(this.collectionName, this.changeViewToReadOnly);
    },
    changeViewToReadOnly: function changeViewToReadOnly() {
      $('.breadcrumb').html($('.breadcrumb').html() + ' (read-only)');
    },
    breadcrumb: function breadcrumb() {
      $('#subNavigationBar .breadcrumb').html('Collection: ' + _.escape(this.collectionName.length > 64 ? this.collectionName.substr(0, 64) + "..." : this.collectionName));
    },
    renderInfoView: function renderInfoView(cluster) {
      if (this.model.get('locked')) {
        return 0;
      }

      var callbackRev = function (error, revision, figures) {
        if (error) {
          arangoHelper.arangoError('Figures', 'Could not get revision.');
        } else {
          var buttons = []; // analyze figures in cluster

          var tableContent = {
            figures: figures,
            revision: revision,
            model: this.model,
            cluster: cluster || {},
            isCluster: frontendConfig.isCluster
          };
          window.modalView.show('modalCollectionInfo.ejs', 'Collection: ' + (this.model.get('name').length > 64 ? this.model.get('name').substr(0, 64) + "..." : this.model.get('name')), buttons, tableContent, null, null, null, null, null, 'content');
          window.arangoHelper.enableSubNavBar();
        }
      }.bind(this);

      var callback = function (error, data) {
        if (error) {
          arangoHelper.arangoError('Figures', 'Could not get figures.');
        } else {
          var figures = data;
          this.model.getRevision(callbackRev, figures);
        }
      }.bind(this);

      this.model.getFiguresCombined(callback, frontendConfig.isCluster);
    }
  });
})();