module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <ul class="navlist arango-collection-ul" id="arangoCollectionUl">\n    ';
 if (isCluster && frontendConfig.clusterApiJwtPolicy !== 'jwt-all') { 
__p+='\n      ';
 if (currentDB.get('isSystem') || statisticsInAllDatabases) { 
__p+='\n        <li class="cluster-menu"><a id="cluster" class="tab" href="#cluster"><i class="fa fa-circle-o"></i>Cluster</a></li>\n      ';
 } 
__p+='\n      <li class="nodes-menu"><a id="nodes" class="tab" href="#nodes"><i class="fa fa-server"></i>Nodes</a></li>\n    ';
 } else if (!isCluster) { 
__p+='\n      <li class="dashboard-menu"><a id="dashboard" class="tab" href="#dashboard"><i class="fa fa-dashboard"></i>Dashboard</a></li>\n    ';
 } 
__p+='\n    <li class="navbar-spacer big"></li>\n    <!-- <li id="dbSelect" class="dropdown databases-menu disabled"></li> -->\n    <li class="collections-menu"><a id="collections" class="tab" href="#collections"><i class="fa fa-folder"></i>Collections</a></li>\n    <li class="analyzers-menu"><a id="analyzers" class="tab" href="#analyzers"><i class="fa fa-cog"></i>Analyzers</a></li>\n    <li class="views-menu"><a id="views" class="tab" href="#views"><i class="fa fa-eye"></i>Views</a></li>\n    <li class="queries-menu"><a id="queries" class="tab" href="#queries"><i class="fa fa-bolt"></i>Queries</a></li>\n    <li class="graphs-menu"><a id="graphs" class="tab" href="#graphs"><i class="fa fa-sitemap"></i>Graphs</a></li>\n    ';
 if (foxxApiEnabled) { 
__p+='\n    <li class="services-menu">\n      <a id="services" class="tab" href="#services"><i class="fa fa-cogs"></i>Services</a>\n    </li>\n    ';
 } 
__p+='\n    ';
 if (currentDB.get('isSystem')) { 
__p+='\n      ';
 if (!readOnly) { 
__p+='\n        <li class="users-menu"><a id="users" class="tab" href="#users"><i class="fa fa-users"></i>Users</a></li>\n      ';
 } 
__p+='\n    ';
 } 
__p+='\n    <!--\n    ';
 if (currentDB.get('isSystem')) { 
__p+='\n      <li class="navbar-spacer big"></li>\n      <li class="settings-menu"><a id="settings" class="tab" href="#settings"><i class="fa fa-cog"></i>Settings</a></li>\n    ';
 } 
__p+='\n    -->\n    ';
 if (currentDB.get('isSystem') || currentDB.get(!isCluster)) { 
__p+='\n      <li class="navbar-spacer big"></li>\n    ';
 } 
__p+='\n    ';
 if (currentDB.get('isSystem')) { 
__p+='\n      <li class="databases-menu"><a id="databases" class="tab" href="#databases"><i class="fa fa-database"></i>Databases</a></li>\n    ';
 } 
__p+='\n    ';
 if (currentDB.get('isSystem') && !isCluster) { 
__p+='\n      <li class="replication-menu"><a id="replication" class="tab" href="#replication"><i class="fa fa-server"></i>Replication</a></li>\n    ';
 } 
__p+='\n    ';
 if (!isCluster) { 
__p+='\n      <li class="logs-menu"><a id="logs" class="tab" href="#logs"><i class="fa fa-file-text"></i>Logs</a></li>\n    ';
 } 
__p+='\n    <li class="navbar-spacer big"></li>\n    <li class="support-menu"><a id="support" class="tab" href="#support"><i class="fa fa-support"></i>Support</a></li>\n    <li class="helpus-menu"><a id="helpus" class="tab" href="#helpus"><i class="fa fa-heart"></i>Help Us</a></li>\n    <li class="enterprise-menu" style="display: none; cursor: pointer"><a id="enterprise" class="tab" target="_blank"><i class="fa fa-external-link-square"></i>Get enterprise</a></li>\n  </ul>\n\n\n  <div class="shortcut-icons">\n    <p class="shortcut"><i class="fa fa-keyboard-o"></i></p>\n  </div>\n\n  <div class="social-icons">\n    <p><a href="https://twitter.com/arangodb" target="_blank"><i class="fa fa-twitter"></i></a></p>\n    <p><a href="https://arangodb-community.slack.com" target="_blank"><i class="fa fa-slack"></i></a></p>\n    <p><a href="https://stackoverflow.com/questions/tagged/arangodb" target="_blank"><i class="fa fa-stack-overflow"></i></a></p>\n    <p><a href="https://groups.google.com/group/arangodb" target="_blank"><i class="fa fa-google"></i></a></p>\n  </div>\n';
}
return __p;
};
